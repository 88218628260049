<template>
  <div class="pagePadding">
    <div class="tabMargin clearfix"><span class="table_title">菜单管理</span> <span class="longTextBtn finger btnReset fr" @click="addMenu({}, 0, 1)" v-if="insertAble">新增顶级菜单</span></div>
    <vxe-table border :stripe="false" resizable :tree-config="{ children: 'children' }" class="tab" :data="list">
      <vxe-table-column type="seq" title="序号" width="68" center align="center"></vxe-table-column>
      <vxe-table-column title="升序" width="68" align="center">
        <template v-slot="{ row, rowIndex }">
          <!-- <span class="finger" @click="move(row, 'up')">上移</span> -->
          <img src="../../assets/images/topUp.png" class="finger" @click="move(row, 'up')" v-if="row.rank == '1' && rowIndex !== 0 && modifyAble" />
          <img src="../../assets/images/secondUp.png" class="finger" @click="move(row, 'up')" v-if="row.rank != '1' && row.order_by != 1 && modifyAble" />
        </template>
      </vxe-table-column>
      <vxe-table-column title="降序" width="68" align="center">
        <template v-slot="{ row, rowIndex }">
          <!-- <span class="finger"  @click="move(row, 'down')">下移</span> -->
          <img src="../../assets/images/topDown.png" class="finger" @click="move(row, 'down')" v-if="row.rank == '1' && rowIndex + 1 != list.length && modifyAble" />
          <img src="../../assets/images/secondDown.png" class="finger" @click="move(row, 'down')" v-if="row.rank != '1' && row.isShowIcon && modifyAble" />
        </template>
      </vxe-table-column>
      <vxe-table-column title="操作" width="220" align="center">
        <template v-slot="{ row, rowIndex }">
          <div class="doClass">
            <span @click="addMenu(row, 1, 2)" v-if="row.rank == 1 && insertAble">新增子菜单</span>
                       <!-- <span @click="addMenu(row, 1, 3)" v-if="row.rank == 2">新增子菜单</span> -->
            <!--            <span @click="addMenu(row, 1, 4)" v-if="row.rank == 3">新增子菜单</span>-->
            <span @click="addMenu(row, 2, 1)" v-if="row.rank == 1 && modifyAble">修改</span>
            <span @click="addMenu(row, 2, 2)" v-if="row.rank == 2 && modifyAble">修改</span>
            <!--            <span @click="addMenu(row, 2, 3)" v-if="row.rank == 3">修改</span>-->
            <!--            <span @click="addMenu(row, 2, 4)" v-if="row.rank == 4">修改</span>-->
            <span @click="deleteMenu(row)" v-if="deleteAble">删除</span>
          </div>
        </template>
      </vxe-table-column>
      <vxe-table-column title="菜单名称" tree-node width="280">
        <template v-slot="{ row }" id="menuTitle">
          <img src="../../assets/images/wenjianjia.png" class="img" v-if="row.rank == 1 && row.children.length > 0" />
          <img src="../../assets/images/fill.png" class="img" v-else />
          <span class="caidan">{{ row.func_name }}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column field="link" title="菜单链接" align="center"></vxe-table-column>
      <!-- <vxe-table-column  field="status" title="是否有效" :formatter="formatterstatus" align='center' width='100'></vxe-table-column>
          <vxe-table-column  field='show' title="是否可见" :formatter="formatterstatus" align='center'  width='100'></vxe-table-column> -->
      <vxe-table-column field="func_desc" title="菜单描述" align="center"></vxe-table-column>
    </vxe-table>
    <div v-if="list.length == 0 && isLoad == false" class="noData">
      <img src="../../assets/images/tableNoData.png" />
    </div>
    <div v-if="isLoad" style="height: 100%; width: 100%; position: absolute">
      <Spin fix>
        <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
        <div>加载中...</div>
      </Spin>
    </div>
    <Modal v-model="addState" label-position="left" :title="title" width="626" :mask-closable="false">
      <div v-if="addState">
        <div class="clearfix">
          <div class="item"><span>菜单名称</span>：<i-input v-model="from.func_name" placeholder="请输入"></i-input></div>
          <div class="item"><span>菜单描述</span>：<i-input v-model="from.func_desc" placeholder="请输入"></i-input></div>
          <div class="item"><span>菜单链接</span>：<i-input v-model="from.link" placeholder="请输入"></i-input></div>
          <div class="item">
            <span>是否有效</span>：
            <Select placeholder="请选择是否有效" v-model="from.status" clearable>
              <Option v-for="(item, index) in status" :value="item.value" :key="index">{{ item.name }}</Option>
            </Select>
          </div>
          <div class="item">
            <span>是否可见</span>：<Select placeholder="请选择是否有效" v-model="from.show" clearable>
              <Option v-for="(item, index) in status" :value="item.value" :key="index">{{ item.name }}</Option>
            </Select>
          </div>
          <div class="item" v-if="!subMenuType"><span>图标</span>：<i-input v-model="from.src" placeholder="请输入"></i-input></div>
          <div class="item" v-if="!subMenuType"><span>点击图标</span>：<i-input v-model="from.clickSrc" placeholder="请输入"></i-input></div>
        </div>
      </div>
      <div slot="footer">
        <span class="pageBtn btnSure finger" @click="ok(1)" v-if="type === 1 || type === 0">确定</span>
        <span class="pageBtn btnSure finger" @click="ok(2)" v-if="type === 2">确定</span>
      </div>
    </Modal>
    <tips-component showModal="delete" v-if="deleteState" @cancleBtn="deleteState = false" @sureBrn="sureDetele"></tips-component>
  </div>
</template>
<script>
import TipsComponent from '@/components/tipsComponent'
export default {
  components: {
    TipsComponent,
  },
  data() {
    return {
      isLoad: false,
      msg: '',
      list: [],
      addState: false,
      status: [
        {
          name: '是',
          value: 'Y',
        },
        {
          name: '否',
          value: 'N',
        },
      ],
      from: {
        func_name: '',
        func_desc: '',
        p_func_id: '', // 添加一级菜单时 传 "0" 二级菜单传父级id
        link: '',
        status: '',
        show: '',
        src: '',
        clickSrc: '',
      },
      type: 1, // 1是新增 2是修改
      title: '新增',
      ruleValidate: {
        func_name: [{ required: true, message: '请填写菜单名称', trigger: 'blur' }],
        link: [{ required: true, message: '请填写菜单链接', trigger: 'blur' }],
        status: [{ required: true, message: '请选择是否有效', trigger: 'change' }],
        show: [{ required: true, message: '请选择是否显示', trigger: 'change' }],
        src: [{ required: true, message: '请输入菜单默认图标', trigger: 'blur' }],
        clickSrc: [{ required: true, message: '请输入菜单点击图标', trigger: 'blur' }],
      },
      menuType: [
        {
          name: '胸痛',
          value: 'xt',
        },
        {
          name: '卒中',
          value: 'cz',
        },
        {
          name: '胸痛卒中',
          value: 'xt_cz',
        },
      ],
      subMenuType: false,
      deleteState: false,
      deleteId: '',
      // 以下是权限按钮显示
      deleteAble: false, // 删除权限
      insertAble: false, // 插入权限
      modifyAble: false, // 修改权限
      searchAble: false, // 查找权限
    }
  },
  created() {
    this.queryList()
    this.getRightVisibal()
  },
  methods: {
    // 移动
    move(row, status) {
      let data = {}
      if (row.rank == 1) {
        // 1级菜单移动
        data = {
          func_id: row.func_id,
          p_set: status,
        }
      } else {
        // 2级菜单移动
        data = {
          func_id: row.func_id,
          c_set: status,
        }
      }
      this.$http.put(this.$api.permissionMenuList, data, true).then(res => {
        this.queryList()
      })
      // this.$api
      //   .menudMove(data)
      //   .then(res => {
      //     if (status === 'up') {
      //       this.$Notice.success({
      //         title: '提示',
      //         desc: '上移成功',
      //       })
      //     } else {
      //       this.$Notice.success({
      //         title: '提示',
      //         desc: '下移成功',
      //       })
      //     }
      //     this.queryList()
      //   })
    },
    // 删除菜单
    deleteMenu(row) {
      this.deleteState = true
      this.deleteId = row.func_id
    },
    sureDetele() {
      this.$http.Delete(this.$api.permissionMenu, this.deleteId, true).then(res => {
        this.queryList()
        this.deleteState = false
      })
    },
    // 新增、修改菜单
    ok(type) {
      if (!this.from.func_name.trim()) {
        this.$Message.warning('请填写菜单名称')
        return
      }
      if (!this.from.link.trim()) {
        this.$Message.warning('请填写菜单链接')
        return
      }
      if (!this.subMenuType) {
        if (!this.from.src.trim()) {
          this.$Message.warning('请填写图标')
          return
        }
        if (!this.from.clickSrc.trim()) {
          this.$Message.warning('请填写点击图标')
          return
        }
      }
      this.from.show = this.from.show ? this.from.show : null
      this.from.status = this.from.status ? this.from.status : null
      if (type === 1) {
        this.$http.post(this.$api.permissionMenuList, this.from, true).then(res => {
          this.addState = false
          this.queryList()
          this.$Message.success('新增菜单成功')
        })
      } else {
        this.$http.Put(this.$api.permissionMenu, this.from.func_id, this.from, true).then(res => {
          this.addState = false
          this.queryList()
        })
      }
    },
    // 查询列表
    queryList() {
      this.isLoad = true
      this.$http.get(this.$api.permissionMenuList, {}, true).then(res => {
        this.isLoad = false
        this.list = res.data
        for (let i = 0; i < this.list.length; i++) {
          for (let j = 0; j < this.list[i].children.length; j++) {
            if (this.list[i].children.length === j + 1) {
              this.list[i].children[j].isShowIcon = false
            } else {
              this.list[i].children[j].isShowIcon = true
            }
          }
        }
      })
    },
    // 点击新增或者修改
    addMenu(row = {}, statu, type) {
      this.type = statu
      if (statu === 1 || statu === 0) {
        this.subMenuType = false
        this.title = '新增'
        this.from = {
          func_name: '',
          func_desc: '',
          p_func_id: row.func_id || '0', // 添加一级菜单时 传 "0" 二级菜单传父级id
          link: '',
          status: '',
          show: '',
          src: row.src || '',
          clickSrc: row.clickSrc || '',
        }
      } else {
        this.subMenuType = true
        this.title = '修改'
        this.from = { ...row }
        this.from.system = 'adm'
        console.log(this.from)
      }
      if (statu === 2 || statu === 1) {
        this.subMenuType = true
      }
      this.addState = true
    },
    // 获取按钮显示权限方法
    getRightVisibal() {
      let navName = this.$route.meta.navName
      let rightStr = JSON.parse(window.sessionStorage.getItem(navName))
      this.deleteAble = rightStr.delete
      this.insertAble = rightStr.insert
      this.modifyAble = rightStr.modify
      this.searchAble = rightStr.search
    },
  },
}
</script>
<style scoped lang="less">
/deep/ .vxe-table--empty-block {
  display: none !important;
}
.tabMargin {
  .table_title {
    font-size: 18px;
    font-weight: bold;
  }
  height: 36px;
  line-height: 36px;
  margin-bottom: 13px;
}
.item {
  display: flex;
  align-items: center;
  justify-items: center;
  margin-bottom: 20px;
  font-size: 16px;
  span {
    width: 100px;
    color: #000;
    text-align: justify;
    height: 36px;
    line-height: 36px;
  }
  span::after {
    content: '';
    display: inline-block;
    padding-left: 100%;
  }
}
.doClass {
  color: #3f65e9;
  span:nth-child(2) {
    margin: 0 20px;
  }
  span {
    cursor: pointer;
  }
}
.img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
}
.caidan {
  display: inline-block;
  vertical-align: middle;
}
.tab {
  margin-bottom: 10px;
  overflow-x: hidden;
}
</style>
